var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.videoPath != "",
            expression: "videoPath != ''"
          }
        ],
        staticClass: "floatingWindow"
      },
      [
        _c(
          "transition",
          { attrs: { name: "fade" } },
          [
            _vm.videoPath != ""
              ? _c("VideoPlayer", { attrs: { config: _vm.videoConfig } })
              : _vm._e()
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }