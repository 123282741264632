<template>
  <transition name="fade">
    <div class="floatingWindow" v-show="videoPath != ''">
      <transition name="fade">
        <VideoPlayer :config="videoConfig" v-if="videoPath != ''"></VideoPlayer>
      </transition>
    </div>
  </transition>
</template>
<script>
import VideoPlayer from "../smallVersion";
export default {
  components: {
    VideoPlayer,
  },
  computed: {
    videoPath() {
      return this.$store.state.videoRelated.videoFloatingWindow.path;
    },
  },
  mounted() {
    this.$set(
      this.videoConfig,
      "currentTime",
      this.$store.state.videoRelated.videoFloatingWindow.currentTime
    );
    this.$set(this.videoConfig, "path", this.videoPath);
  },
  data() {
    return {
      // 视频配置
      videoConfig: {
        path: "",
        type: "small",
        volumeControl: true,
        floatingWindowPlay: true,
        isFloating: true,
        currentTime: 0,
        startPlayingDirectly: true,
      },
    };
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s, transform .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform translate3d(0,30px,0);
}
.floatingWindow
  width 29.42vw;
  height 16.5625vw;
  border-radius 0.416vw;
  overflow hidden;
  position fixed;
  bottom 1.46vw;
  right 2.29vw;
  z-index 9999;
  display flex;
  align-items center;
  justify-content center;
</style>
